footer {
    position: fixed;
    bottom: 5%;
    width: 100%;
}

.footer-text {
    font-size: small;
    text-align: center;
    flex: 1 1 auto;
}

.App {
    text-align: center;
}

.data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
}

.navbarStyle {
    padding: .5rem 1rem !important
}

.navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
}

.iconText {
    margin: 0 .5rem
}

.tableColumn {
    word-break: break-all
}

.table {
    max-height: 37rem;
}

.centered {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centeredHorizontally {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .scroll-content {
    display: inline-block;
    white-space: nowrap;
    padding-left: 16px;
  }
  
  .custom-scrollbar {
    height: 8px;
    background-color: #f1f1f1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .scrollbar-thumb {
    height: 100%;
    background-color: #888;
    cursor: pointer;
  }
